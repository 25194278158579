<template>
  <div class="container position-relative">
    <!-- begin::Legacy device alert -->
    <template v-if="equipment.isDeviceLinked() && device.isLegacyDevice()">
      <div class="loading-container loading-overlap m-n5 h-auto">
        <div class="loading-backdrop rounded opacity-20" />
        <div class="loading-block">
          <div class="alert alert-custom alert-light-info ml-auto mr-auto w-100 p-3 d-block">
            <div
              class="alert-text"
              v-html="$t('sunbeds.legacy_device_warning')"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Legacy device alert -->

    <div class="row">
      <div class="col-12">
        <div class="alert alert-custom alert-light-info" role="alert">
          <div class="alert-icon">
            <i>
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </i>
          </div>
          <div class="alert-text">
            Keeping your sunbed’s lamp temperatures within indicated parameters increases life expectancy and tanning quality and experience.<br />
            Review your sunbed’s manual for more information.
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom shadow-none">
      <!-- begin::Errors alert -->
      <template v-if="isErrorActive">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop" />
          <div class="loading-block w-75">
            <error-translation-alert
              ref="errorTranslationAlert"
              :error="activeError"
              class="shadow"
            />
          </div>
        </div>
      </template>
      <!-- end::Error alert -->

      <!-- begin::Card header -->
      <div class="card-header flex-wrap px-0">
        <div class="card-title">
          <h3 class="card-label">
            Temperatures
            <span class="text-muted pt-2 font-size-sm d-block">Temperatures of sunbed.</span>
          </h3>
        </div>

        <div class="card-toolbar w-100 w-md-auto">
          <b-dropdown
            ref="range-dropdown"
            variant="primary"
            class="btn-block btn-clean btn-dropdown dropdown-menu-top-unround"
            right
            lazy
            no-caret
            menu-class="w-100 w-md-500px"
          >
            <template v-slot:button-content>
              <i class="mt-n1">
                <font-awesome-icon :icon="['fas', 'calendar-alt']" />
              </i>

              <span class="pl-3">
                <template v-if="$moment.tz(datePicker.range.start, datePicker.timezone).isSame(datePicker.range.end, 'date')">{{ $moment.tz(datePicker.range.start, datePicker.timezone).format("LL") }}</template>
                <template v-else>{{ $moment.tz(datePicker.range.start, datePicker.timezone).format("LL") }} - {{ $moment.tz(datePicker.range.end, datePicker.timezone).format("LL") }}</template>
              </span>
            </template>

            <template v-slot:default>
              <div class="row mx-0">
                <div class="col-12 border-bottom col-md-8 border-bottom-md-0 border-right-md">
                  <date-picker
                    mode="date"
                    class="border-0 my-auto"
                    color="primary"
                    locale="en"
                    is-range
                    is-expanded

                    :value="datePicker.range"
                    :min-date="datePicker.minDate"
                    :max-date="datePicker.maxDate"
                    :input-props="{ class: 'border-0' }"
                    :first-day-of-week="2"
                    :timezone="datePicker.timezone"

                    @input="onDatePickerInput"
                  />
                </div>

                <div class="col pt-5 pt-md-0">
                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).startOf('day').toDate(),
                      end: $moment.tz(datePicker.timezone).endOf('day').toDate()
                    })"
                  >
                    Today
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).subtract(1, 'days').startOf('day').toDate(),
                      end: $moment.tz(datePicker.timezone).subtract(1, 'days').endOf('day').toDate()
                    })"
                  >
                    Yesterday
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).startOf('isoWeek').toDate(),
                      end: $moment.tz(datePicker.timezone).toDate()
                    })"
                  >
                    This week
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).subtract(1, 'isoWeek').startOf('isoWeek').toDate(),
                      end: $moment.tz(datePicker.timezone).subtract(1, 'isoWeek').endOf('isoWeek').toDate()
                    })"
                  >
                    Last week
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).startOf('month').toDate(),
                      end: $moment.tz(datePicker.timezone).toDate()
                    })"
                  >
                    This month
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).subtract(1, 'month').startOf('month').toDate(),
                      end: $moment.tz(datePicker.timezone).subtract(1, 'month').endOf('month').toDate()
                    })"
                  >
                    Last month
                  </div>
                </div>
              </div>
            </template>
          </b-dropdown>
        </div>
      </div>
      <!-- end::Card header -->

      <!-- begin::Card body -->
      <div class="card-body px-0">
        <div class="row">
          <div
            v-for="row in temperatureSensors"
            :key="`equipment.${equipment._meta.guid}.temperature.${row.sensor}.chart`"
            class="col-12 col-lg-6 mt-3 px-0 px-lg-6"
          >
            <div class="card card-custom">
              <!-- begin::Loading -->
              <template v-if="isMounted === false || isLoading">
                <div class="loading-container loading-overlap">
                  <div class="loading-backdrop" />
                  <div class="loading-block">
                    <div class="blockui ml-auto mr-auto w-100">
                      <span class="float-left">Gathering data...</span>
                      <span class="spinner spinner-primary ml-auto" />
                    </div>
                  </div>
                </div>
              </template>
              <!-- end::loading -->

              <!-- begin::Card header -->
              <div class="card-header flex-wrap">
                <div class="card-title">
                  <h3 class="card-label">
                    {{ $tu(`sunbeds.models.${equipment.model}.temperatures.${row.sensor}.name`) || `Sensor '${row.sensor}'` }}
                    <span v-if="$te(`sunbeds.models.${equipment.model}.temperatures.${row.sensor}.description`)" class="text-muted pt-2 font-size-sm d-block">{{ $t(`sunbeds.models.${equipment.model}.temperatures.${row.sensor}.description`) }}</span>
                  </h3>
                </div>
              </div>
              <!-- end::Card header -->

              <!-- begin::Chart -->
              <apexchart
                ref="chart"
                class="card-rounded-bottom h-250 mt-auto"
                :type="chart.type"
                :height="chart.height"
                :options="row.chartOptions"
                :series="(chart.series || []).filter((serie) => serie.sensor === row.sensor)"
              />
              <!-- end::Chart -->
            </div>
          </div>
        </div>
      </div>
      <!-- end::Card body -->
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/plugins/datepicker/index.scss';
</style>

<script>
import ApexCharts from 'vue-apexcharts';
import moment from 'moment-timezone';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import { errorComponentMixin } from '@vedicium/core-vue';
import { cloneDeep } from 'lodash';

import Equipment, { SunbedHealth } from '@/libs/classes/equipment';

import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [equipmentMixin, errorComponentMixin],
  components: {
    errorTranslationAlert,

    DatePicker,
    apexchart: ApexCharts,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      data: [],

      datePicker: {
        range: {
          start: null,
          end: null,
        },
        timezone: this.equipment.location.timezone || 'UTC',
        minDate: moment.tz(this.equipment.location.timezone || 'UTC').set('year', 2016).startOf('year').toDate(),
        maxDate: new Date(),
        attributes: [{
          bar: {
            class: 'bg-primary',
          },
          highlight: {
            class: 'bg-primary',
            contentClass: 'bg-success',
          },
        }],
      },

      chart: {
        type: 'area',
        height: 400,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          plotOptions: {},
          colors: [this.$metronic.config.layout.get('colors.theme.base.primary'), this.$metronic.config.layout.get('colors.theme.base.dark')],
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'solid',
            opacity: [0.5, 0],
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary'), this.$metronic.config.layout.get('colors.theme.base.dark')],
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
            width: 3,
            colors: [this.$metronic.config.layout.get('colors.theme.base.primary'), this.$metronic.config.layout.get('colors.theme.base.dark')],
          },
          xaxis: {
            type: 'datetime',
            min: null,
            max: null,
            datetimeUTC: false,
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: true,
              formatter: (timestamp) => moment.tz(timestamp, this.datePicker.timezone).format('HH:mm [on] DD MMM'),
            },
            tooltip: {
              enabled: false,
              style: {
                colors: this.$metronic.config.layout.get('colors.gray.gray-500'),
                fontSize: '12px',
                fontFamily: this.$metronic.config.layout.get('font-family'),
              },
            },
          },
          yaxis: [{
            seriesName: 'Temperature',
            min: 0,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => `${value / 10} °C`,
            },
          }],
          markers: {
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary'), this.$metronic.config.layout.get('colors.theme.base.dark')],
            strokeColor: [this.$metronic.config.layout.get('colors.theme.base.primary'), this.$metronic.config.layout.get('colors.theme.base.dark')],
            strokeWidth: 3,
          },
        },
        series: [],
      },
    };
  },
  computed: {
    temperatureSensors () {
      if (!this.equipment) {
        return [];
      }

      const sunbedHealth = SunbedHealth.find((maintenanceHours) => maintenanceHours.model.includes(this.equipment.model));
      if (!sunbedHealth) {
        return [];
      }

      return (sunbedHealth.temperatures || []).map((row) => {
        // eslint-disable-next-line prefer-object-spread
        const rowObject = { ...row, chartOptions: cloneDeep(this.chart.options) };

        if (typeof row.modifyChartOptions === 'function') {
          rowObject.chartOptions = row.modifyChartOptions(rowObject.chartOptions);
        }

        return rowObject;
      });
    },
  },

  async mounted () {
    this.$set(this.datePicker, 'range', {
      start: moment.tz(this.datePicker.timezone).startOf('day').toDate(),
      end: moment.tz(this.datePicker.timezone).endOf('day').toDate(),
    });

    await this.$nextTick(); // Await next tick so temperature sensors are computed

    try {
      await this.getChartData();
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async onDatePickerInput (range = null) {
      if (!range || !range.start || !range.end) {
        return;
      }

      if (range.start instanceof Date === false || range.end instanceof Date === false) {
        return;
      }

      // Hide dropdown and set range
      this.$refs['range-dropdown'].hide();
      this.$set(this.datePicker, 'range', {
        start: moment.tz(range.start, this.datePicker.timezone).startOf('day').toDate(),
        end: moment.tz(range.end, this.datePicker.timezone).endOf('day').toDate(),
      });

      await this.$nextTick();

      // Reload chart data
      try {
        await this.getChartData();
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this, ui_element: false });
      }
    },

    async getChartData () {
      this.$errors.clear({ component: this });
      this.$set(this, 'data', []);

      if (this.device && this.device.isLegacyDevice() === true) {
        return this.data;
      }

      this.$set(this, 'isLoading', true);

      try {
        this.$set(this, 'data', await Promise.all(this.temperatureSensors.map((row) => { // eslint-disable-line
          return (async () => {
            const response = await this.$ws.get(`${Equipment.uri}/statistics/temperatures/${row.sensor}/1m/${this.datePicker.range.start.getTime()}/${this.datePicker.range.end.getTime()}`, {
              query: {
                equipment: (this.equipment ? this.equipment._meta.guid : ''),
                offset: this.datePicker.timezone,
                'filter[controller_state]': [7, 10], // State 7 is in session & state 10 is pause
              },
            });

            return {
              sensor: row.sensor,
              data: response,
            };
          })();
        })));
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.configureChartData(this.data || []);

      return this.data;
    },
    async configureChartData (data = null) {
      const series = this.temperatureSensors.map((row) => {
        const sensorObject = {
          sensor: row.sensor,
          name: 'Temperature',
          data: [],
        };

        // Add data of backend
        sensorObject.data = sensorObject.data.concat((data || []).filter((dataRow) => dataRow.sensor === row.sensor).flatMap((dataRow) => { // eslint-disable-line
          return (dataRow.data && (dataRow.data.results || []).map((result) => { // eslint-disable-line
            return [moment.tz(result.date, this.datePicker.timezone).valueOf(), result.average];
          }));
        }));

        return sensorObject;
      });

      this.$set(this.chart, 'series', series);
    },
  },
};
</script>
